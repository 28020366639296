export default class SelectedClassStorage {
	constructor(private readonly endpoint, private readonly login) {
		if (typeof endpoint !== 'string') {
			throw new Error('Endpoint must be string, got: ' + typeof endpoint)
		}
		if (typeof login !== 'string') {
			throw new Error('Login must be string, got: ' + typeof login)
		}
	}

	get key() {
		return `${this.login}_${this.endpoint}_selectedClass`
	}

	get(): string {
		return localStorage.getItem('agmir_' + this.key)
	}

	set(value: string) {
		localStorage.setItem('agmir_' + this.key, value)
	}
}
