import { apiClient } from '@/api/client'
import { Api, Reference, ReferenceDraft, ReferencePatch } from '~/shared'

export const get = async (
	endpoint: string,
	id: string,
	language?: string
): Promise<Api.Response<Reference>> => {
	let url = '/endpoints/' + endpoint + '/references/' + encodeURIComponent(id)
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.get(url, {
		headers: { 'Content-Type': 'application/json' },
	})
	return response.data
}

export const getMany = async (
	endpoint: string,
	ids: string[],
	language?: string
): Promise<Api.Response<Reference[]>> => {
	let url = '/endpoints/' + endpoint + '/references/many'
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.post(url, ids, {
		headers: { 'Content-Type': 'application/json' },
	})
	return response.data
}

export const list = async (
	endpoint: string,
	_class: string,
	language?: string,
	version?: string
): Promise<Api.Response<Reference[]>> => {
	let url = `/endpoints/${endpoint}/class/${encodeURIComponent(_class)}/references`
	const query = []
	if (version) {
		query.push(`version=${encodeURIComponent(version)}`)
	}
	if (language) {
		query.push(`language=${encodeURIComponent(language)}`)
	}
	if (query.length){
		url = `${url}?${query.join('&')}`
	}
	const response = await apiClient.get(url, {
		headers: { 'Content-Type': 'application/json' },
	})
	return response.data
}

export const create = async (
	endpoint: string,
	data: ReferenceDraft,
	language?: string
): Promise<Api.Response<Reference>> => {
	let url = '/endpoints/' + endpoint + '/references'
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.post(url, data, {
		headers: { 'Content-Type': 'application/json' },
	})
	return response.data
}

export const patch = async (
	endpoint: string,
	patch: ReferencePatch,
	language?: string
): Promise<Api.Response<Reference>> => {
	const { id } = patch
	let url = '/endpoints/' + endpoint + '/references/' + encodeURIComponent(id)
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.patch(url, patch, {
		headers: { 'Content-Type': 'application/json' },
	})
	return response.data
}

export const remove = async (endpoint: string, id: string) => {
	await apiClient.delete('/endpoints/' + endpoint + '/references/' + encodeURIComponent(id))
}
