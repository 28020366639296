import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {getList as getEndpoints} from "@/api/endpoints";
import {Endpoint} from "@/types";
import router from '@/router'

class State {
  endpoints: Endpoint[] = []
  endpoint: Endpoint = null
}

const getters: GetterTree<State, any> = {
  endpoints: state => state.endpoints
}

const mutations: MutationTree<State> = {
  setEndpoints(state, payload: Endpoint[]){
    state.endpoints = payload
  },
  setEndpoint(state, payload: string) {
    const endpoint = state.endpoints.find((item) => item.id === payload)
    if (!endpoint) {
      throw new Error(`Не найдена точка доступа ${payload}. \nДоступны: ${state.endpoints.map((e) => e.id)
                                                                         .join(', ')}`)
    }
    localStorage.setItem('agmir_endpoint', endpoint.id)
    state.endpoint = endpoint
    this.dispatch('lang/init')
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    let endpoints = await getEndpoints();
    context.commit('setEndpoints', endpoints)

    let endpointId = router.history.current.params.endpoint
    if (!endpointId) {
      endpointId = localStorage.getItem('agmir_endpoint')

      if (!endpointId) {
        endpointId = context.state.endpoints[0].id
      }
    }
    context.commit('setEndpoint', endpointId)
  },
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
