export type Listener<P> = (payload: P) => void
export type OffControl = () => void

export default class EventEmitter<T> {
	private events: Map<keyof T, Set<Listener<T[keyof T]>>> = new Map()

	private getOrCreateEventSet<K extends keyof T>(kind: K): Set<Listener<T[K]>> {
		if (!this.events.has(kind)) {
			const set = new Set<Listener<T[K]>>()
			this.events.set(kind, set)
			return set
		} else {
			return this.events.get(kind)
		}
	}

	getEventSet<K extends keyof T>(kind: K): Set<Listener<T[K]>> {
		return this.events.get(kind)
	}

	clear(): void {
		this.events.clear()
	}

	emit<K extends keyof T>(kind: K, payload: T[K]): void {
		const set = this.getEventSet(kind)
		if (set) {
			set.forEach((listener) => listener(payload))
		}
	}

	on<K extends keyof T>(kind: K, listener: Listener<T[K]>): OffControl {
		const set = this.getOrCreateEventSet(kind)
		set.add(listener)
		return () => {
			set.delete(listener)
		}
	}
}
