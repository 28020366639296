import { apiClient } from '@/api/client'
import { Api } from '~/shared'
import { Attribute, AttributeDraft, AttributePatch } from '~/shared/new-types'

export const get = async (
	endpoint: string,
	id: string,
	language?: string
): Promise<Api.Response<Attribute>> => {
	let url = '/endpoints/' + endpoint + '/attributes/' + encodeURIComponent(id)
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.get(url, {
		headers: { 'Content-Type': 'application/json' },
	})
	return response.data
}

export const getMany = async (
	endpoint: string,
	ids: string[],
	language?: string
): Promise<Api.Response<Attribute[]>> => {
	let url = '/endpoints/' + endpoint + '/attributes/many'
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.post(url, ids, {
		headers: { 'Content-Type': 'application/json' },
	})
	return response.data
}

export const list = async (
	endpoint: string,
	_class: string,
	language?: string,
	version?: string
): Promise<Api.Response<Attribute[]>> => {
	let url = `/endpoints/${endpoint}/class/${encodeURIComponent(_class)}/attributes`
	const query = []
	if (version) {
		query.push(`version=${encodeURIComponent(version)}`)
	}
	if (language) {
		query.push(`language=${language}`)
	}
	if (query.length){
		url = `${url}?${query.join('&')}`
	}
	const response = await apiClient.get(url, {
		headers: { 'Content-Type': 'application/json' },
	})
	return response.data
}

export const create = async (
	endpoint: string,
	data: AttributeDraft,
	language?: string
): Promise<Api.Response<Attribute>> => {
	let url = '/endpoints/' + endpoint + '/attributes'
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.post(url, data, {
		headers: { 'Content-Type': 'application/json' },
	})
	return response.data
}

export const patch = async (
	endpoint: string,
	patch: AttributePatch,
	language?: string
): Promise<Api.Response<Attribute>> => {
	const { id } = patch
	let url = '/endpoints/' + endpoint + '/attributes/' + encodeURIComponent(id)
	if (language) {
		url = url + '?language=' + language
	}
	const response = await apiClient.patch(url, patch, {
		headers: { 'Content-Type': 'application/json' },
	})
	return response.data
}

export const remove = async (endpoint: string, id: string) => {
	await apiClient.delete('/endpoints/' + endpoint + '/attributes/' + encodeURIComponent(id))
}
