import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {getLang} from "@/utils/lang";
import {getEndpoint} from "@/utils/endpoints";
import {openSocket} from "@/socket";
import {ClientSocket} from "~/shared";


let lang
let endpointId
let webSocket: ClientSocket = null

let timer: any

class State {
	isRebuild = false
	isSubscribe = false
}

const getters: GetterTree<State, any> = {
	isRebuild(state): boolean {
		return state.isRebuild
	},
}

const mutations: MutationTree<State> = {
	setStatus(state, payload: boolean) {
		state.isRebuild = payload
	},
	setSubscribe(state, payload: boolean) {
		state.isSubscribe = payload
	},
	socketClose(){
		if (webSocket) {
			webSocket.close()
		}
	}
}

const actions: ActionTree<State, any> = {
	async init(context, payload?: string) {
		lang = getLang()

		endpointId = payload || await getEndpoint(context)
		webSocket = await openSocket(endpointId, lang)

		webSocket.subscribe('cacheRebuildStart', async ({ action, user }) => {
			console.info('received action', { action, user })
			if (action.type === "cache") {
				context.commit('setStatus', true)
			}
		})
		webSocket.subscribe('handleSubscribePackageStart', async ({ action, user }) => {
			console.info('received action', { action, user })
			if (action.type === "cache") {
				if (timer) clearTimeout(timer)
				context.commit('setSubscribe', true)
			}
		})

		webSocket.subscribe('cacheRebuildFinish', async ({ action, user }) => {
			console.info('received action', { action, user })
			if (action.type === "cache") {
				context.commit('setStatus', false)

				const versions = context.rootState.ontologyVersions
				const currentClass = context.rootState.classes.current
				let isLastVersion = true
				if (versions.access){
					isLastVersion = versions.viewVersion === null || versions.viewVersion === versions.lastVersion.uri
				}
				if (isLastVersion) {
					context.dispatch('constants/init', null, {root: true})
					context.dispatch('classes/init', null, {root: true})
					if (currentClass) context.dispatch('classes/loadClass', currentClass.id, {root: true})
				}
			}
		})
		webSocket.subscribe('handleSubscribePackageFinish', async ({ action, user }) => {
			console.info('received action', { action, user })
			if (action.type === "cache") {
				if (timer) clearTimeout(timer)
				timer = setTimeout(() => context.commit('setSubscribe', false), 5_000)
			}
		})
	}
}

export default {
	namespaced: true,
	state: new State(),
	mutations: mutations,
	actions: actions,
	getters: getters,
}
