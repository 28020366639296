import router from '@/router'
import {compose, equals, prop} from 'ramda'
import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {
  Attribute, AttributeType,
  Class,
  ClassShallowProps, DataAction, DataActionType,
  OWL,
  RDFS,
  Reference,
  ShallowClass, UserInfo
} from "~/shared";
import {get as getRoot} from "@/api/root";
import {getClass, getClasses} from "@/api/classes";
import {getLang} from "@/utils/lang";
import {Endpoint} from "@/types";
import {equalsById} from "@/functions";
import SelectedClassStorage from "@/core/selected-class-storage";
import {getMany as getAttributes, list as listAttributes} from "@/api/attributes";
import {getMany as getReferences, list as listReferences} from "@/api/references";
import {openSocket} from "@/socket";


let lang
let endpoint: Endpoint
let selectedClassStorage

let webSocket
let webSocketCache

function closeSocket() {
  if (!webSocket) return
  webSocket.close()
}

async function initSocket(context) {
  const endpoint = context.rootState.endpoints.endpoint
  const currentClass = context.state.current
  webSocket = await openSocket(endpoint.id, lang.id, currentClass.id)
  webSocket.subscribe('action', ({ action, user }) => {
    const versions = context.rootState.ontologyVersions
    let isLastVersion = true
    if (versions.access){
      isLastVersion = versions.viewVersion === null || versions.viewVersion === versions.lastVersion.uri
    }
    if (isLastVersion) {
      console.info('received action', {action, user})
      if (action.type === OWL.DatatypeProperty || action.type === OWL.ObjectProperty) {
        if (action.action === 'set') {
          handlePropertySet(context, action as DataAction<'set', AttributeType>, user)
        }
        else if (action.action === 'remove') {
          handlePropertyRemove(context, action as DataAction<'remove', AttributeType>, user)
        }
      }
      if (action.type === OWL.NamedIndividual) {
        this.dispatch('namedIndividualAction', {
          action: action as DataAction<DataActionType, OWL.NamedIndividual>,
          user,
        })
      }
    }
  })
}

let handleAttributeTimeout = null
let handleAttributeList: string[] = []
let handleReferenceTimeout = null
let handleReferenceList: string[] = []

function handlePropertySet(context, action: DataAction<'set', AttributeType>, user?: UserInfo) {
  const endpoint = context.rootState.endpoints.endpoint
  const lang = context.rootState.lang.currentLang
  if (action.type === OWL.DatatypeProperty) {
    handleAttributeList.push(...action.objects.map((e) => e.id))
    if (handleAttributeTimeout) {
      clearTimeout(handleAttributeTimeout)
    }
    handleAttributeTimeout = setTimeout(async () => {
      const ids = handleAttributeList
      handleAttributeList = []
      const { data } = await getAttributes(endpoint.id, ids, lang.id)
      for (const item of data) {
        // this.setAttribute(item)
        if (context.state.literals.find(equalsById(item))) {
          context.commit('patchLiteral', item)
        } else {
          context.commit('addLiteral', item)
        }
        // this.dispatch('setAttribute', { data: item, user })
      }
    }, 1000)
  }

  if (action.type === OWL.ObjectProperty) {
    handleReferenceList.push(...action.objects.map((e) => e.id))
    if (handleReferenceTimeout) {
      clearTimeout(handleReferenceTimeout)
    }
    handleReferenceTimeout = setTimeout(async () => {
      const ids = handleReferenceList
      handleReferenceList = []
      const { data } = await getReferences(endpoint.id, ids, lang.id)
      for (const item of data) {
        // this.setReference(item)
        if (context.state.references.find(equalsById(item))) {
          context.commit('patchReference', item)
        } else {
          context.commit('addReference', item)
        }
        // this.dispatch('setReference', { data: item, user })
      }
    }, 1000)
  }
}

function handlePropertyRemove(context, action: DataAction<'remove', AttributeType>, user?: UserInfo) {
  if (action.type === OWL.DatatypeProperty) {
    action.objects.map(({ id }) => {
      // this.removeAttribute(id)
      context.commit('removeLiteral', id)
      // this.dispatch('removeAttribute', { id, user })
    })
  }
  if (action.type === OWL.ObjectProperty) {
    action.objects.map(({ id }) => {
      // this.removeReference(id)
      context.commit('removeReference', id)
      // this.dispatch('removeReference', { id, user })
    })
  }
}

class State {
  pending: boolean = false

  root: ShallowClass = null
  classes: ShallowClass[] = []

  current: Class = null
  literals: Attribute[] = []
  references: Reference[] = []
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
  clear(state) {
    state.root = null
    state.current = null
    state.classes = []
    state.literals = []
    state.references = []
  },

  setPending(state, payload) {
    state.pending = payload
  },

  setRoot(state, payload) { state.root = payload},
  setClasses(state, payload) {state.classes = payload},

  addClass(state, payload: ShallowClass) {
    const indexOfClass = state.classes.findIndex(equalsById(payload))
    if (!payload.props) return
    if (indexOfClass > -1) {
      this.commit('classes/patchClass', payload)
    }
    else {
      state.classes.push(payload)
    }
  },
  patchClass(state, payload: ShallowClass) {
    const actualClass = state.classes.find(({id}) => id === payload.id)
    if (actualClass) {
      if (payload.access) {
        actualClass.access = payload.access
      }
      if (payload.operations) {
        actualClass.operations = payload.operations
      }
      if (payload.props) {
        if (actualClass.props === undefined || actualClass.props === null)
          actualClass.props = {} as ClassShallowProps
        actualClass.props = Object.assign(actualClass.props, payload.props)
        if (actualClass.name !== payload.name) {
          actualClass.name = payload.name
          state.classes.forEach((item) => {
            item.props[RDFS.subClassOf].forEach((parent) => {
              if (parent.id === payload.id) {
                parent.name = payload.name
              }
            })
          })
        }
      }
    }
  },
  removeClass(state, id: string) {
    const indexOfClass = state.classes.findIndex((item) => item.id === id)
    if (indexOfClass > -1) {
      state.classes.splice(indexOfClass, 1)
    }
    state.classes.forEach((item) => {
      const index = item.props[RDFS.subClassOf].findIndex(compose(equals(id), prop(id)))
      if (index > -1) {
        item.props[RDFS.subClassOf].splice(index, 1)
      }
    })
  },

  setCurrentClass(state, payload){
    state.current = payload
    selectedClassStorage.set(payload.id)
  },

  setLiterals(state, payload) {
    state.literals = payload
  },
  setReferences(state, payload) {
    state.references = payload
  },

  addLiteral(state, payload) {
    state.literals.push(payload)
  },
  patchLiteral(state, payload) {
    const currentAttribute = state.literals.find((el) => el.id === payload.id)
    if (!currentAttribute) return

    Object.assign(currentAttribute.props, payload.props)
    currentAttribute.name = payload.name
  },
  removeLiteral(state, payload) {
    const attributeIndex = state.literals.findIndex((el) => payload === el.id)
    if (attributeIndex > -1) {
      state.literals.splice(attributeIndex, 1)
    }
  },

  addReference(state, payload) {
    state.references.push(payload)
  },
  patchReference(state, payload) {
    const currentAttribute = state.references.find((el) => el.id === payload.id)
    if (!currentAttribute) return

    Object.assign(currentAttribute.props, payload.props)
    currentAttribute.name = payload.name
  },
  removeReference(state, payload) {
    const attributeIndex = state.references.findIndex((el) => payload === el.id)
    if (attributeIndex > -1) {
      state.references.splice(attributeIndex, 1)
    }
  }
}

const actions: ActionTree<State, any> = {
  async init(context) {
    lang = getLang()
    endpoint = context.rootState.endpoints.endpoint
    const user = context.rootState.auth.user
    selectedClassStorage = new SelectedClassStorage(endpoint.id, user.login)
    context.commit('clear')

    context.commit('setRoot', (await getRoot(endpoint.id, lang)).data)
    context.commit('setClasses', (await getClasses(endpoint.id, lang, context.rootState.ontologyVersions.viewVersion)).data)
    await context.dispatch('initClass')
  },
  async loadClass(context, payload) {
    context.commit('setPending', true)
    try {
      closeSocket()
      const lang = getLang()
      const endpoint = context.rootState.endpoints.endpoint
      const viewVersion = context.rootState.ontologyVersions.viewVersion
      const cls = await getClass(endpoint.id, payload, lang, viewVersion)

      const [attributesResponse, referencesResponse] = await Promise.all([
        listAttributes(endpoint.id, payload, lang, viewVersion),
        listReferences(endpoint.id, payload, lang, viewVersion),
      ])

      context.commit('setCurrentClass', cls.data)
      context.commit('setLiterals', attributesResponse.data)
      context.commit('setReferences', referencesResponse.data)

      initSocket(context)
    } catch (e) {

    }
    context.commit('setPending', false)
  },
  initClass(context) {
    let classId = router.history.current.params.class

    if (!classId) {
      classId = selectedClassStorage.get()
      if (!classId) return
    }

    if (classId) {
      const _class = context.state.classes.find((el) => el.id === classId)
      if (!_class) return

      context.dispatch('loadClass', classId)
      router.push({
        name: 'editorMainClassIndividuals',
        params: {
          class: classId,
          endpoint: endpoint.id,
        },
        query: {
          'object-id': classId,
          'object-type': OWL.Class,
        },
      })
    }
  },
  select(context, payload) {
    const route = router.history.current
    const params = {
      class: payload,
      endpoint: endpoint.id,
    }
    const query = {
      'object-type': OWL.Class,
      'object-id': payload,
    }
    const handleError = (err) => {
      // Ignore the vuex err regarding  navigating to the page they are already on.
      if (err.name !== 'NavigationDuplicated' &&
        !err.message.includes('Avoided redundant navigation to current location')) {
        // But print any other errors to the console
        console.error(err)
      }
    }

    context.dispatch('loadClass', payload)

    if (route.name === 'editorMainClassLiterals') {
      router
        .push({
          name: 'editorMainClassLiterals',
          params,
          query,
        })
        .catch(handleError)
      return
    }
    if (route.name === 'editorMainClassReferences') {
      router
        .push({
          name: 'editorMainClassReferences',
          params,
          query,
        })
        .catch(handleError)
      return
    }
    router
      .push({
        name: 'editorMainClassIndividuals',
        params,
        query,
      })
      .catch(handleError)
  }
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
